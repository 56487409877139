import $ from "jquery";

$('body').on('mouseover', '.bio-type-btn', function() {
    console.log($(this).data('type'));
    $('.bio-type-btn').removeClass('active');
    $(this).addClass('active');
    let bioType = $(this).data('type');

    $('.bio-text').hide();
    $('.' + bioType).show();
})


// Testimonials
setInterval(() => {
    let $activeTestominal = $('#testimonials li.active');
    let testimonialCount = $('#testimonials li').length;
    let $nextTestimonial = $activeTestominal.next('li');
    let currentTestimonialIndex = $nextTestimonial.index();
    if(currentTestimonialIndex < testimonialCount ) {
        if(currentTestimonialIndex == -1) {
            $('#testimonials li').removeClass('active');
            $('#testimonials li:first').addClass('active');
        } else {
            $nextTestimonial.addClass('active');
            $activeTestominal.removeClass('active');
        }
    }
}, 4000)
